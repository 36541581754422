import email from '../assets/email.png'
import mobile from '../assets/mobile.png'
import api from '../assets/api.png'
import cpp from '../assets/cpp.png'
import css from '../assets/css.png'
import figma from '../assets/figma.png'
import flutter from '../assets/flutter.png'
import git from '../assets/git.png'
import graphql from '../assets/graphql.png'
import html from '../assets/html.png'
import javascript from '../assets/javascript.png'
import mu5 from '../assets/mu5.png'
import node from '../assets/node.png'
import python from '../assets/python.png'
import react from '../assets/react.png'
import redux from '../assets/redux.png'
import sass from '../assets/sass.png'
import typescript from '../assets/typescript.png'
import vue from '../assets/vue.png'

import profile from '../assets/figure-1.png'
import circle from '../assets/circle.svg'
import logo from '../assets/name.png'

export default {
  email,
  mobile,
  api,
  cpp,
  css,
  figma,
  flutter,
  git,
  graphql,
  html,
  javascript,
  mu5,
  node,
  python,
  react,
  redux,
  sass,
  typescript,
  vue,
  profile,
  circle,
  logo,
}
